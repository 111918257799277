import { navigate } from "gatsby";
import moment from "moment";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ReviewDetailForm from "../ReviewForm/ReviewDetailForm";
import ReviewFormHeader from "../ReviewForm/ReviewFormHeader";

const GuestBooking = (props) => {
  const initialValues = {
    otherData: null,
  };

  const [reviewFormData, setReviewFormData] = useState(initialValues);

  const [isSave, setIsSave] = useState(false);
  const [dataError, setDataError] = useState();

  const onFinish = () => {
    // if(data.Name === null || data.TelNo === null){

    // }
    let data = {
      branch: props.branch,
      Name: reviewFormData.otherData.name,
      DateofBirth: reviewFormData.otherData.dateOfBirth,
      DateofAnniversary: reviewFormData.otherData.dateOfAnniversary,
      MobileNo: reviewFormData.otherData.mobileNo,
      NoOfPAX: reviewFormData.otherData.noofpax,
    };

    let tempData = {
      Name: data.Name,
      Date_of_Birth: data.DateofBirth,
      Date_of_Anniversary: data.DateofAnniversary,
      Mobile_No: data.MobileNo,
      No_Of_PAX: data.NoOfPAX,
      CreateDTTM: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    if (
      tempData.Date_of_Birth !== "" &&
      tempData.Name !== "" &&
      tempData.Mobile_No !== "" &&
      tempData.No_Of_PAX !== ""
    ) {
      fetch(
        data.branch === "andheri"
          ? "https://script.google.com/macros/s/AKfycbwQCS2xu0DPqEPGyCR_rDhlymErcSXzhV19SOq2gL6f8QtmKY7pHNBoy_v-fCyfe9CP/exec?action=andheri"
          : data.branch === "xci"
          ? "https://script.google.com/macros/s/AKfycbyWKWHLtMjnI_ynxigRuqXOmteJ2740-GoI6sUOc88JBhrzi6612ma2OiDNn-Igzuz3/exec?action=xci"
          : "https://script.google.com/macros/s/AKfycbzmp--rDGrAOvzJ1BCReM26GB9yv6RJrhsMiGakJEKsGJMo77QtN-VDULvdmOZ5UC0G/exec?action=ametrine_24",
        {
          // header: {
          //   "Content-type": "application/json;charset=utf-8",
          //   // "Access-Control-Allow-Origin": true,
          //   // "Access-Control-Allow-Method": "POST",
          // },
          method: "POST",
          body: JSON.stringify(tempData),
          mode: "cors",
        }
      )
        .then((response) => {
          // console.log(tempData, "tempDta4");

          if (response.status === 200) {
            navigate(`/thank-you?form=${"feedback"}`);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
      setReviewFormData(initialValues);
      setIsSave(!isSave);
    } else {
      if (
        tempData.Date_of_Birth === "" ||
        tempData.Name === "" ||
        tempData.Mobile_No === "" ||
        tempData.No_Of_PAX === ""
      ) {
        setDataError("Please fill the fields that are marked in red *");
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        ></meta>
      </Helmet>
      <div
        className={`${
          props.formType === "GuestData" ? "md:h-screen" : "md:h-full"
        } z-10 h-full`}
        style={{
          backgroundColor: `${
            props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
          }`,
          color: `${props.branch === "ametrine_24" ? "#21659b" : "#ebb834"}`,
        }}
      >
        <div className="mx-auto max-w-6xl">
          <div className="md:px-14 px-6">
            <ReviewFormHeader branch={props.branch} formType={"GuestData"} />
            <ReviewDetailForm
              formType={"GuestData"}
              data={isSave}
              otherDetail={(data) => {
                setReviewFormData({ ...reviewFormData, otherData: data });
                setDataError();
              }}
            />
          </div>
          {dataError && <div className="md:px-14 px-6 pb-4">{dataError}</div>}
          <div className="md:px-14 px-6 block lg:flex justify-start w-full pb-5">
            <button
              style={{
                backgroundColor: `${
                  props.branch === "ametrine_24" ? "#21659b" : "#ebb834"
                }`,
                color: `${
                  props.branch === "ametrine_24" ? "#e1f3fd" : "#3c0c4a"
                }`,
              }}
              className="rounded px-8 py-2 text-white font-semibold w-full outline-none focus:ring-1 ring-yellow-600"
              onClick={() => {
                onFinish();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestBooking;
